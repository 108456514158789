import api from "../utils/api";

const ordersService = {
  list: async (organization_id, page = 1, pageSize = 10) => {
    try {
      const response = await api.get(`/order/order-organization/?organization_id=${organization_id}&page=${page}&page_size=${pageSize}`)
      return response
    } catch (error) {
      console.error("Error fetching Organization Orders!", error);
    }
  },
  details: async (order_id) => {
    try {
      const response = await api.get(`/order/order-organization/${order_id}/`)
      return response
    } catch (error) {
      console.error("Error fetching order details.", error)
    }
  },
  update: async (order_id, payload) => {
    try {
      const response = await api.put(`/order/order-organization/${order_id}/`, payload)
      return response
    } catch (error) {
      console.error("Error updating order details.", error)
    }
  },
  delete: async (order_id) => {
    try {
      const response = await api.delete(`/order/order-organization/${order_id}/`)
      if (response === "") {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error("Error deleting order", error)
    }
  },
  create: async (payload) => {
    try {
      const response = await api.post("/order/order-organization/", payload)
      if (response.id) {
        return response
      }

      return false
    } catch (error) {
      console.error("Error creating order.", error)
    }
  },
  createUnassignedOrder: async (payload) => {
    try {
      const response = await api.post("/order/orders/", payload)
      if (response.id) {
        return response
      }

      return false
    } catch (error) {
      console.error("Error creating order.", error)
    }
  },
  deleteUnassignedOrder: async (order_id) => {
    try {
      const response = await api.delete(`/order/orders/${order_id}/`)
      if (response === "") {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error("Error deleting order", error)
    }
  },
  adjustmentTypes: async () => {
    try {
      const response = await api.get("/admin/adjustmenttypes/")
      return response.results
    } catch (error) {
      console.error("Error fetching adjustments types.", error)
    }
  },
  updateFulfillmentHistory: async (payload) => {
    try {
      const response = await api.post("/dispatch/fullfilment-history/", payload)
      return response.data
    } catch (error) {
      console.error("Error updating fulfillment history")
    }
  }
}

export default ordersService;

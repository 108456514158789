import { Navigate, useNavigate } from "react-router-dom"
import InputGroup from "../../../components/InputGroup"
import Button from "../../../components/Button"
import { useAuth } from "../../../context/AuthContext"

const ForgotPasswordPage = () => {
  const { loggedIn } = useAuth()

  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('Handle Submit')
  }

  return loggedIn ? <Navigate to="/dashboard" /> : (
    <div className="flex items-center justify-center h-screen">
      <div className='flex flex-col items-start justify-start gap-4 max-w-[30rem] w-full'>
        <p className="font-normal text-lg"><strong className="text-2xl">Forgot your password?</strong> Please enter your email.</p>
        <p className="font-normal text-base">You will receive an email to reset your password.</p>
        <form className='self-stretch flex flex-col gap-4' onSubmit={handleSubmit}>
          <InputGroup label='Email' id='email' name='email' type='email' />
          <div className="self-end flex items-center justify-end gap-4">
            <Button value='Cancel' type='button' btnStyle="secondary" onClick={() => navigate('/')} />
            <Button value='Send' type='submit' />
          </div>
        </form>
      </div>
    </div>
  )
}
 
export default ForgotPasswordPage
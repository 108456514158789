import { useState } from "react";
import Button from "./Button";
import CalendarIcon from "../assets/icons/Calendar.svg";

const DateRange = ({ startDate, endDate, setDate, applyChanges }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target
    setDate(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <div className="relative flex items-center justify-start gap-2 p-2 border border-black rounded-lg cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)}>
      <p className="font-normal text-sm leading-none select-none">Data from <strong>{startDate.replaceAll('-', '/')}</strong> to <strong>{endDate.replaceAll('-', '/')}</strong></p>
      <div className="inline-block w-[1px] h-3 bg-black" />
      <img src={CalendarIcon} alt="Calendar icon" className="w-4 h-4" />
      <div className={`${isMenuOpen ? 'block' : 'hidden'} absolute top-[130%] right-0 flex flex-col items-end justify-start gap-2 w-full p-2 border border-black rounded-lg bg-white`} onClick={(e) => e.stopPropagation()}>
        <p className="self-start font-semibold text-sm">Set the date range</p>
        <div className="self-stretch flex items-start justify-between">
          <div className="flex flex-col items-start justify-start">
            <label htmlFor="startDate" className="font-semibold text-xs">From:</label>
            <input
              id="startDate"
              name="from"
              type="date"
              value={startDate}
              className="w-32 py-1 px-2 border border-black rounded-lg text-sm leading-none"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="flex flex-col items-start justify-start">
            <label htmlFor="endDate" className="font-semibold text-xs">To:</label>
            <input
              id="endDate"
              name="to"
              type="date"
              value={endDate}
              className="w-32 py-1 px-2 border border-black rounded-lg text-sm leading-none"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <Button value="Apply" onClick={() => {
          applyChanges()
          setIsMenuOpen(false)
        }} />
      </div>
    </div>
  );
}

export default DateRange;
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from "chart.js"
import { Line } from "react-chartjs-2"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const LineChart = ({ labels, data, datasetLabel }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: datasetLabel,
        data: data,
        tension: 0,
        fill: true,
        borderColor: 'rgb(1, 119, 253)',
        backgroundColor: 'rgba(1, 119, 253, .5)',
        pointRadius: 4,
        pointBorderColor: 'rgb(1, 119, 253)',
        pointBackgroundColor: 'rgb(1, 119, 253)'
      }
    ]
  }

  const options = {
    scales: {
      y: {
        min: 0
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  return <Line data={chartData} options={options} />
}

export default LineChart

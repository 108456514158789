import CheckIcon from '../assets/icons/Check.svg'

const RadioButton = ({ label = '', id = '', name = '', value, checked, onChange, isBtnType = false, isCheckbox = false }) => {
  return (
    <div>
      <input
        type={isCheckbox ? "checkbox" : "radio"}
        className="hidden"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className={`${isBtnType ? (checked ? 'bg-grey-800' : 'bg-grey-600') + ' inline-block px-4 py-1.5 text-sm font-semibold rounded-lg capitalize text-white' : 'flex items-center justify-start gap-4 text-sm font-normal capitalize'}`}>
        {
          !isBtnType &&
            <div className={`${checked && 'bg-black'} w-5 h-5 border-2 border-black rounded`}>
              <img src={CheckIcon} alt="Check icon" className={!checked ? 'hidden' : undefined} />
            </div>
        }
        {label}
      </label>
    </div>
  );
}
 
export default RadioButton;
import { useState } from "react";
import { Outlet } from "react-router-dom";
import MainHeader from "./header/MainHeader";
import Sidemenu from "./sidebar/sidemenu";
import { ShoppingCartProvider } from "../context/ShoppingCartContext";

const Layout = () => {
  const [isSidemenuOpen, setIsSidemenuOpen] = useState(false);
  const [isSidebarContainerVisible, setIsSidebarContainerVisible] = useState(false);

  const openSidemenu = () => {
    setIsSidebarContainerVisible(true)
    setTimeout(() => {
      setIsSidemenuOpen(true)
    }, 10)
  }

  const closeSidemenu = () => {
    setIsSidemenuOpen(false)
    setTimeout(() => {
      setIsSidebarContainerVisible(false)
    }, 300)
  }

  return (
    <ShoppingCartProvider>
      <div className={`${isSidebarContainerVisible ? "block" : "hidden"} fixed h-dvh w-dvw bg-transparent_black z-50`} onClick={closeSidemenu}>
        <Sidemenu withHeader className={`${isSidemenuOpen && "translate-x-0"} fixed top-0 left-0 z-10 -translate-x-full duration-300 ease-in-out`} toggleOpen={closeSidemenu} />
      </div>
      <MainHeader openSidemenu={openSidemenu} />
      <div className="flex items-stretch justify-start" style={{ height: 'calc(100dvh - 5rem)' }}>
        <Sidemenu className="max-lg:hidden" />
        <main className="flex-1 px-4 py-4 overflow-y-scroll">
          <Outlet />
        </main>
      </div>
    </ShoppingCartProvider>
  );
}
 
export default Layout;

const Button = ({ type = 'button', value = 'Button', btnStyle = 'default', onClick, isDisabled = false }) => {
  let buttonStyle

  switch (btnStyle.toLowerCase()) {
    case 'default':
      buttonStyle = 'bg-black text-white shadow-[0_4px_4px_0px_rgba(0,0,0,0.4)] border border-black'
      break
    case 'danger':
      buttonStyle = 'bg-custom_red text-white shadow-[0_4px_4px_0px_rgba(0,0,0,0.4)] border border-custom_red'
      break
    case 'secondary':
      buttonStyle = 'text-black border border-black'
      break
    default:
      buttonStyle = 'bg-black text-white shadow-[0_4px_4px_0px_rgba(0,0,0,0.4)] border border-black'
  }

  return (
    <button
      className={`${!isDisabled && 'hover:opacity-80'} px-4 py-1.5 text-sm font-semibold rounded-lg capitalize ${buttonStyle} ${isDisabled && 'opacity-40 cursor-not-allowed'}`}
      type={type}
      onClick={onClick}
      disabled={isDisabled}
    >
      {value}
    </button>
  )
}

export default Button

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DateBadge, StatusBadge } from "../../../components/Badges";
import { getFormattedDate } from "../../../utils/dateFormatter";
import Button from "../../../components/Button";
import Table, { TableCell } from "../../../components/Tables";
import itemService from "../../../services/itemsService";
import ordersService from "../../../services/ordersService";
import SelectInput from "../../../components/SelectInput";
import NotesInput from "../../../components/NotesInput";
import addressesService from "../../../services/addressesService";
import { useAuth } from "../../../context/AuthContext";
import fulfillmentMethodsService from "../../../services/fulfillmentMethodsService";
import StateTimeline from "../../../components/StateTimeline";
import TrashIcon from "../../../assets/icons/Trash.svg"
import XIcon from "../../../assets/icons/XIcon.svg"
import styles from "../../../components/styles/tableLoader.module.css"
import { PDFDownloadLink } from "@react-pdf/renderer";
import OrderDetailsPDF from "./OrderDetailsPDF";

const OrderDetailsPage = () => {
  const location = useLocation()
  const {id} = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()

  const [orderDetails, setOrderDetails] = useState();
  const [orderPayload, setOrderPayload] = useState(orderDetails);
  const [isEditing, setIsEditing] = useState(false);
  const [allItems, setAllItems] = useState();
  const [adjustmentTypes, setAdjustmentTypes] = useState();
  const [addresses, setAddresses] = useState();
  const [fulfillments, setFulfillments] = useState();
  const [newLineItemsForms, setNewLineItemsForms] = useState([]);
  const [notesNumber, setNotesNumber] = useState();
  const [notes, setNotes] = useState();
  const [lineItemsToDelete, setLineItemsToDelete] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalContainerOpen, setIsModalContainerOpen] = useState(false);
  const [warehouseToPickup, setWarehouseToPickup] = useState();

  const openModal = () => {
    setIsModalContainerOpen(true)
    setTimeout(() => {
      setIsModalOpen(true)
    }, 10)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      setIsModalContainerOpen(false)
    }, 200)
  }

  const fetchDetails = async () => {
    if (id) {
      const response = await ordersService.details(id)
      setOrderDetails(response)
    }
  }

  const fetchData = async () => {
    const adjustments = await ordersService.adjustmentTypes()
    setAdjustmentTypes(adjustments)

    const fulfillmentsResponse = await fulfillmentMethodsService.list()
    setFulfillments(fulfillmentsResponse)

    const addressesResponse = await addressesService.organizationAddresses(user.organizations[0]?.organization)
    setAddresses(addressesResponse.map(address => {
      return {
        id: address.id,
        name: `${address.address_detail.street}, ${address.address_detail.exterior}, ${address.address_detail.interior}, ${address.address_detail.city}, ${address.address_detail.country_detail?.printable_name}`
      }
    }))

    const items = await itemService.listFiltered(`?category_parents=marca`)
    setAllItems(items)
  }

  const fetchWarehouse = async (warehouseID) => {
    const response = await itemService.warehouseDetails(warehouseID)
    if (response) {
      setWarehouseToPickup(response)
    }
  }

  useEffect(() => {
    if (id) {
      fetchDetails()
    } else if (location.state) {
      setOrderDetails(location.state.order)
    } else {
      console.error("No order has been specified.")
    }
  }, []);

  useEffect(() => {
    if (orderDetails) {
      if (orderDetails.order.fulfillment_method === 4) {
        const warehouseID = orderDetails.order.order_line_items_details[0].warehouse
        fetchWarehouse(warehouseID)
      }
      setOrderPayload(orderDetails)
      fetchData()
    }
  }, [orderDetails]);

  const handleLineItems = (event, index) => {
    const { name, value } = event.target
    setOrderPayload(prevPayload => ({
      ...prevPayload,
      order: {
        ...prevPayload.order,
        order_line_items_details: prevPayload.order.order_line_items_details.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              [name]: name === "quantity" ? ((value === '' || !Number(value)) ? 0 : parseInt(value)) : value
            }
          }
          return item
        })
      }
    }))
  }

  const handleOrderData = (event) => {
    const { name, value } = event.target
    setOrderPayload(prevPayload => {
      const newOrder = {
        ...prevPayload.order,
        [name]: parseInt(value)
      }

      if (name === "fulfillment_method" && parseInt(value) === 4) {
        newOrder.shipping_address = null
      }

      return {
        ...prevPayload,
        order: newOrder
      }
    })
  }

  useEffect(() => {
    if (orderPayload) {
      setNotesNumber(Object.keys(orderPayload.order.notes || {0: ""}).length)
      setNotes(Object.entries(orderPayload.order.notes || {0: ""}).map(([key, value]) => {
        return {
          note: value
        }
      }) || [])
    }
  }, [orderPayload]);

  const notesHandleChange = (index, event) => {
    const newNotes = [...notes]
    newNotes[index].note = event.target.value
    setNotes(newNotes)
    setOrderPayload(prevPayload => ({
      ...prevPayload,
      order: {
        ...prevPayload.order,
        notes: newNotes.reduce((notesObj, note, i) => {
          notesObj[i] = note.note
          return notesObj
        }, {})
      }
    }))
  }

  const addNote = () => {
    setNotesNumber(notesNumber + 1)
    setNotes(prevNotes => ([
      ...prevNotes,
      {
        note: ""
      }
    ]))
  }

  const removeNote = (index) => {
    setNotesNumber(notesNumber - 1)
    const newNotes = notes.filter((_, noteIndex) => noteIndex !== index)
    setNotes(newNotes)
    setOrderPayload(prevPayload => ({
      ...prevPayload,
      order: {
        ...prevPayload.order,
        notes: newNotes.reduce((notesObj, note, i) => {
          notesObj[i] = note.note
          return notesObj
        }, {})
      }
    }))
  }

  const handelNewLineItems = (event, index) => {
    const { name, value } = event.target
    setNewLineItemsForms(prevNLI => {
      const updatedNLI = [...prevNLI]

      updatedNLI[index] = {
        ...updatedNLI[index],
        [name]: name === "quantity" ? ((value === '' || !Number(value)) ? 0 : parseInt(value)) : value
      }

      return updatedNLI
    })
  }

  const deleteNewLineItem = (index) => {
    setNewLineItemsForms(prevNLI => prevNLI.filter((_, i) => i !== index))
  }

  const lineItemsTotal = orderPayload?.order.order_line_items_details.reduce((total, lineItem) => {
    return total + (lineItem.quantity * lineItem.price)
  }, 0)

  const adjustmentsTotal = orderPayload?.order.adjustments_detail.reduce((total, adjustment)=> {
    return total + parseFloat(adjustment.amount)
  }, 0)

  const finalTotal = lineItemsTotal + adjustmentsTotal

  const deleteLineItem = (index) => {
    if (!lineItemsToDelete.includes(index)) {
      setLineItemsToDelete(prev => [...prev, index])
    }
    setOrderPayload(prevPayload => ({
      ...prevPayload,
      order: {
        ...prevPayload.order,
        order_line_items_details: prevPayload.order.order_line_items_details.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              item: orderDetails.order.order_line_items_details[index].item,
              quantity: orderDetails.order.order_line_items_details[index].quantity
            }
          }
          return item
        })
      }
    }))
  }

  const resetLineItem = (index) => {
    setLineItemsToDelete(prev => prev.filter(value => value !== index))
  }

  const cancelEdit = () => {
    setOrderPayload(orderDetails)
    setNotesNumber(Object.keys(orderDetails.order.notes || {0: ""}).length)
    setNotes(Object.entries(orderDetails.order.notes || {0: ""}).map(([key, value]) => {
      return {
        note: value
      }
    }) || [])
    setNewLineItemsForms([])
    setLineItemsToDelete([])
  }

  const saveChanges = async () => {
    setIsEditing(false)
    const lineItemsEdited = orderPayload.order.order_line_items_details.filter((lineItem, index) => !lineItemsToDelete.includes(index)).map(lineItem => ({
      item: lineItem.item,
      quantity: lineItem.quantity
    }))

    const newLineItems = newLineItemsForms.filter(lineItem => lineItem.item !== 0 && lineItem.quantity !== 0)

    const updates = {
      order: {
        fulfillment_method: orderPayload.order.fulfillment_method,
        shipping_address: orderPayload.order.shipping_address,
        billing_address: orderPayload.order.billing_address,
        notes: orderPayload.order.notes,
        order_line_items_details: [...lineItemsEdited, ...newLineItems]
      }
    }

    console.log(updates)
    // TODO: adapt and send edited details
    const response = await ordersService.update(orderPayload.id, updates)
    if (response) {
      console.log(response)
    }
  }

  const deleteOrder = async () => {
    const deleted = await ordersService.delete(orderPayload.id)
    if (deleted) {
      navigate("/orders")
    }
  }

  if (!orderPayload) {
    return (
      <div className="flex flex-col items-stretch justify-start gap-6">
        <div className="flex items-end justify-between">
          <div className="flex items-end justify-start gap-4">
            <div className={`${styles.loader} w-24 h-9`} />
            <div className={`${styles.loader} w-24 h-9`} />
          </div>
          <div  className={`${styles.loader} w-24 h-9`} />
        </div>
        <div className={`${styles.loader} h-56`} />
        <div className="flex items-end justify-end">
          <div className={`${styles.loader} h-48 max-w-80 w-full`} />
        </div>
        <div className={`${styles.loader} h-60 max-w-96 w-full`} />
      </div>
    )
  }

  return (
    <div className="flex flex-col items-stretch justify-start gap-6">
      <div className={`bg-transparent_black fixed top-0 left-0 h-dvh w-dvw p-4 ${isModalContainerOpen ? 'flex' : 'hidden'} items-center justify-center`} onClick={closeModal}>
        <div className={`bg-white max-w-96 w-full p-4 rounded-lg flex flex-col items-stretch justify-start gap-4 scale-0 duration-200 ease-linear ${isModalOpen && 'scale-100'}`} onClick={e => e.stopPropagation()}>
          <h4 className="font-bold text-lg text-pretty">Are you sure you want to delete this order (#{orderPayload.id})?</h4>
          <p className="text-sm text-pretty">Deleting this order is a permanent action and cannot be undone.</p>
          <div className="flex items-center justify-end gap-4 flex-wrap">
            <Button btnStyle="secondary" value="Keep order" onClick={closeModal} />
            <Button btnStyle="danger" value="Delete" onClick={deleteOrder} />
          </div>
        </div>
      </div>
      <div className="flex items-end justify-between">
        <div className="flex items-start justify-start gap-8">
          <DateBadge label="created" date={getFormattedDate(orderPayload.order.created)} />
          <DateBadge label="updated" date={getFormattedDate(orderPayload.order.updated)} />
        </div>
        <p className="font-medium text-xs">Order ID: <span className="font-semibold text-xl">{orderPayload.id}</span></p>
      </div>
      <div className="flex items-center justify-between gap-4">
        <StatusBadge isActive={orderPayload.order.approval} text={orderPayload.order.approval ? 'Approved' : 'Not Approved'} />
        {
          !isEditing &&
            <PDFDownloadLink
              document={<OrderDetailsPDF order={orderDetails} adjustmentTypes={adjustmentTypes} warehouseToPickup={warehouseToPickup} />}
              fileName={`Order_Details_${orderPayload.id}`}
              style={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '.5rem',
                paddingInline: '1rem',
                paddingBlock: '.375rem',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '20px'
              }}
            >
              Download PDF
            </PDFDownloadLink>
        }
      </div>
      <div className="overflow-x-scroll">
        <Table
          headers={['items', 'unit price', 'quantity', `${isEditing ? 'actions' : 'total'}`]}
          className="min-w-[48rem]"
        >
          {
            Array.from({ length: orderPayload.order.order_line_items_details.length }).map((_, index) => (
              <tr key={index} className={`${!isEditing && 'odd:bg-grey'}`}>
                <TableCell>
                  {
                    <select
                      name="item"
                      value={orderPayload.order.order_line_items_details[index].item}
                      className={`min-w-80 w-full outline-none ${!isEditing ? 'bg-transparent appearance-none opacity-100' : 'px-2 py-1 bg-grey rounded-lg'} ${lineItemsToDelete.includes(index) && 'opacity-40'}`}
                      disabled={!isEditing || lineItemsToDelete.includes(index)}
                      onChange={(e) => handleLineItems(e, index)}
                    >
                      {
                        allItems
                          ?
                            allItems.map((item) => (
                              <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                          :
                            <option value={orderPayload.order.order_line_items_details[index].item}>{orderPayload.order.order_line_items_details[index].item_detail.name}</option>
                      }
                    </select>
                  }
                </TableCell>
                <TableCell className="w-40">{!isEditing && `$${orderPayload.order.order_line_items_details[index].price}`}</TableCell>
                <TableCell className="w-40">
                  <input
                    name="quantity"
                    value={parseInt(orderPayload.order.order_line_items_details[index].quantity)}
                    className={`outline-none w-full ${!isEditing ? 'bg-transparent appearance-none' : 'px-2 py-1 bg-grey rounded-lg'} ${lineItemsToDelete.includes(index) && 'opacity-40'}`}
                    disabled={!isEditing || lineItemsToDelete.includes(index)}
                    onChange={(e) => handleLineItems(e, index)}
                  />
                </TableCell>
                <TableCell>
                {
                  isEditing
                    ?
                      <div className="flex items-center justify-center">
                        <button
                          className="h-7 w-7 p-1"
                          type="button"
                          onClick={() => {
                            if (lineItemsToDelete.includes(index)) {
                              resetLineItem(index)
                            } else {
                              deleteLineItem(index)
                            }
                          }}
                        >
                          <img
                            src={lineItemsToDelete.includes(index) ? XIcon : TrashIcon}
                            alt="buton icon"
                          />
                        </button>
                      </div>
                    :
                      `$${orderPayload.order.order_line_items_details[index].total_price.toFixed(2)}`
                }
                </TableCell>
              </tr>
            ))
          }
          {
            !isEditing &&
              <tr>
                <td></td>
                <td className="px-2.5 py-1.5 bg-grey-600 font-semibold text-lg">Totals</td>
                <td className="px-2.5 py-1.5 bg-grey-600">
                  {
                    orderPayload.order.order_line_items_details.reduce((acc, lineItem) => {
                       return acc + parseInt(lineItem.quantity)
                    }, 0)
                  }
                </td>
                <td className="px-2.5 py-1.5 bg-grey-600">${lineItemsTotal.toFixed(2)}</td>
              </tr>
          }
          {
            isEditing &&
              Array.from({ length: newLineItemsForms.length }).map((_, index) => (
                <tr key={index} className="bg-gray-100">
                  <TableCell>
                    <select
                      name="item"
                      value={newLineItemsForms[index]?.item}
                      className="min-w-80 w-full outline-none px-2 py-1 bg-grey rounded-lg"
                      onChange={(e) => handelNewLineItems(e, index)}
                    >
                      <option disabled value={0}>Select Item</option>
                      {
                        allItems.map((item) => (
                          <option key={item.id} value={item.id}>{item.name}</option>
                        ))
                      }
                    </select>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <input
                      name="quantity"
                      value={newLineItemsForms[index]?.quantity}
                      className="outline-none w-full px-2 py-1 bg-grey rounded-lg"
                      disabled={!isEditing || lineItemsToDelete.includes(index)}
                      onChange={(e) => handelNewLineItems(e, index)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center justify-center" onClick={() => deleteNewLineItem(index)}>
                      <button type="button" className="h-7 w-7 p-1">
                        <img src={TrashIcon} alt="Trash icon to delete line item" />
                      </button>
                    </div>
                  </TableCell>
                </tr>
              ))
          }
        </Table>
        {
          isEditing &&
            <div className="flex items-center justify-end pt-2">
              <Button value="Add line item" btnStyle="secondary" onClick={() => setNewLineItemsForms(prev => ([...prev, { item: 0, quantity: 0 }]))} />
            </div>
        }
      </div>
      
      {
        !isEditing && (
          <>
            <div className="flex flex-col items-end justify-start gap-2">
              <h2 className="font-semibold text-xl">Adjustments</h2>
              <table>
                <tbody>
                  {
                    orderPayload.order.adjustments_detail.length > 0
                      ?
                        Array.from({ length: orderPayload.order.adjustments_detail.length }).map((_, index) => (
                          <tr key={index}>
                            <td className="text-right text-sm font-semibold pr-2 capitalize">{adjustmentTypes?.find(type => type.id === orderPayload.order.adjustments_detail[index].adjustment_type)?.name}:</td>
                            <td className="text-lg">${orderPayload.order.adjustments_detail[index].amount}</td>
                          </tr>
                        ))
                      :
                        <tr>
                          <td><p className="text-sm">This order has no adjustments.</p></td>
                        </tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-end">
              <p className="font-semibold text-xl bg-grey px-4 py-2 rounded-lg">Total: <span className="font-bold text-2xl">${finalTotal?.toFixed(2)}</span></p>
            </div>
          </>
        )
      }
      <div className="flex items-end justify-between gap-4 max-sm:flex-col max-sm:items-stretch">
        <div className="flex flex-col items-stretch justify-start gap-4 max-w-[30rem]">
          <SelectInput
            id="fulfillment"
            options={fulfillments || [orderPayload.order.fulfillment_method_detail]}
            name="fulfillment_method"
            label="fulfillment method"
            value={orderPayload.order.fulfillment_method}
            isDisabled={!isEditing}
            onChange={handleOrderData}
          />
          {
            orderPayload.order.fulfillment_method !== 4 &&
              <SelectInput
                id="shipping_address"
                options={addresses || [{ id: 2, name: 'dsdpio' }]}
                name="shipping_address"
                label="shipping address"
                value={orderPayload.order.shipping_address || 0}
                isDisabled={!isEditing}
                onChange={handleOrderData}
              />
          }
          <SelectInput
            id="billing_address"
            options={addresses}
            name="billing_address"
            label="billing address"
            value={orderPayload.order.billing_address}
            isDisabled={!isEditing}
            onChange={handleOrderData}
          />
          <NotesInput
            label="notes"
            inputsNumber={notesNumber}
            notes={notes}
            handleChange={notesHandleChange}
            addNote={addNote}
            removeNote={removeNote}
            isDisabled={!isEditing}
          />
        </div>
        {
          warehouseToPickup &&
            <div className="flex flex-col items-start justify-start gap-2">
              <p className="font-semibold text-base">The order pickup will be at:</p>
              <div className="max-w-[25rem]">
                <p className="font-semibold text-sm">{warehouseToPickup.name}</p>
                <p className="text-sm">{warehouseToPickup.address.location}</p>
              </div>
            </div>
        }
      </div>
      {
        !isEditing && (
          <div className="flex flex-col items-start justify-start gap-2">
            <h2 className="font-semibold text-xl">Fulfillment State History</h2>
            <StateTimeline states={orderPayload.order.fulfillment_state_history} />
          </div>
        )
      }
      {
        !orderPayload.order.approval &&
          <div className="flex items-end justify-end gap-4">
            {
              !isEditing
                ?
                  <Button value="Edit" onClick={() => setIsEditing(true)} />
                :
                  <>
                    <Button
                      value="cancel"
                      onClick={() => {
                        setIsEditing(false)
                        cancelEdit()
                      }}
                      btnStyle="secondary"
                    />
                    <Button value="Delete" btnStyle="danger" onClick={openModal} />
                    <Button value="Save" onClick={saveChanges} />
                  </>
            }
          </div>
      }
    </div>
  );
}
 
export default OrderDetailsPage;
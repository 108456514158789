import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import DropdownFilters from "./DropdownFilters";
import ChevronLeft from "../assets/icons/ChevronLeft.svg";
import ChevronRight from "../assets/icons/ChevronRight.svg";
import { FilterBadge } from "./Badges";

export const TableContainer = ({
  children,
  totalPages,
  showAddButton = true,
  filterFields,
  filters,
  setFilters,
  filtersInitialState,
  applyFilters,
  resetFilters,
  updatePagination,
  pageSizes = [10, 20, 30]
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("add");
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const updatePageSize = (size) => {
    setPageSize(size)
    setCurrentPage(1)
  }

  useEffect(() => {
    if (currentPage && updatePagination) {
      updatePagination(currentPage, pageSize)
    }
  }, [currentPage, pageSize])

  const [badges, setBadges] = useState();

  const updateBadges = () => {
    if (filters) {
      const newBadges = Object.keys(filters)?.map((filter, index) => {
        if (filters[filter] !== filtersInitialState[filter]) {
          return <FilterBadge key={index} filterLabel={filter} filterValue={filters[filter]} onClick={() => deleteFilter(filter)} />
        } else {
          return null
        }
      })
      setBadges(newBadges)
    }
  }

  const deleteFilter = (filterLabel) => {
    setFilters({
      ...filters,
      [filterLabel]: filtersInitialState[filterLabel]
    })
  }

  useEffect(() => {
    if (applyFilters !== undefined) {
      applyFilters()
    }
    updateBadges()
  }, [filters])

  return (
    <div>
      <div className="flex flex-col items-stretch justify-start gap-2 mb-2">
        {filters && (
          <div className="flex items-center justify-between">
            <DropdownFilters>
              {filterFields}
              <div className="flex items-center justify-end gap-4">
                <Button
                  btnStyle="secondary"
                  value="Reset"
                  onClick={() => {
                    if (resetFilters !== undefined) {
                      resetFilters()
                    }
                    updateBadges()
                  }}
                />
              </div>
            </DropdownFilters>
            {showAddButton && <Button type="button" value="add" onClick={handleAddClick} />}
          </div>
        )}
        <div className="flex items-center justify-start flex-wrap gap-4 h-6">
          {badges}
        </div>
      </div>
      {children}

      {totalPages && (
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center justify-start gap-4">
            <span className="text-sm font-normal py-4">Show:</span>
            {
              pageSizes.map((size, index) => (
                <button key={index} className={`px-2 py-1 rounded-md text-base font-normal leading-5 ${size === pageSize ? 'bg-grey-600' : 'bg-grey'}`} onClick={() => updatePageSize(size)}>{size}</button>
              ))
            }
          </div>
          <div className="flex items-center justify-start gap-4">
            <button
              className="flex items-center justify-center hover:bg-grey w-6 h-8 rounded-md"
              onClick={previousPage}
            >
              <img src={ChevronLeft} alt="Chevron left icon" />
            </button>
            <span className="text-sm">Page</span>
            <input
              type="number"
              name="currentPage"
              id="currentPage"
              min={1}
              value={currentPage}
              onChange={(event) => setCurrentPage(parseInt(event.target.value))}
              className="px-2 py-1 bg-grey rounded-md text-xl font-semibold leading-4 w-12 outline-none text-center"
            />
            <span className="text-sm">of {totalPages}</span>
            <button
              className="flex items-center justify-center hover:bg-grey w-6 h-8 rounded-md"
              onClick={nextPage}
            >
              <img src={ChevronRight} alt="Chevron right icon" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export const TableCell = ({ children, className }) => {
  return (
    <td className={`px-2.5 py-1.5 font-normal text-base ${className}`}>
      {children !== "null" ? children : " - "}
    </td>
  );
};

const Table = ({
  headers,
  children,
  withDetails = false,
  detailsURL = "",
  className,
}) => {
  return (
    <table className={`w-full border-b border-black ${className}`}>
      <thead>
        <tr>
          {headers.map((heading, index) => (
            <th
              className="px-2.5 py-1.5 border-b-2 border-black text-lg leading-5 font-semibold capitalize text-left"
              key={index}
            >
              {heading.replace("_", " ")}
            </th>
          ))}
          {withDetails && (
            <th className="px-2.5 py-1.5 border-b-2 border-black text-lg leading-5 font-semibold capitalize text-left w-24">
              Details
            </th>
          )}
        </tr>
      </thead>
      <tbody className="">{children}</tbody>
    </table>
  );
};

export default Table;

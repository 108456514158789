const DashboardInfo = ({ label = 'label', value }) => {
  return (
    <div className="flex flex-col items-start justify-start gap-2 min-w-48 w-60 h-fit p-4 bg-grey rounded-2xl">
      <span className="font-normal text-xs capitalize">{label}</span>
      <p className="self-stretch font-bold text-2xl text-center">{value}</p>
    </div>
  )
}
 
export default DashboardInfo

import { useState } from "react";
import Chevron from "../assets/icons/Chevron.svg"

const DropdownFilters = ({ children }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  return (
    <div className="relative w-fit">
      <button
        className="flex items-center justify-start gap-8 h-8 border border-black px-1.5 rounded-lg outline-none"
        onClick={toggleFilters}
      >
        <span>Filters</span>
          <img src={Chevron} alt="Chevron down icon" className={`w-5 h-5 ${isFilterOpen ? '-rotate-90' :'rotate-90'} transition-rotate duration-200 ease-linear`} />
      </button>
      <article className={`${isFilterOpen ? 'flex' : 'hidden'} flex-col items-stretch justify-start gap-4 w-[22.5rem] p-4 bg-white rounded-2xl border border-black absolute top-10`}>
        <h3 className="text-base font-semibold">Filters</h3>
        <div className="flex flex-col items-stretch justify-start gap-4">
          {children}
        </div>
      </article>
    </div>
  );
}

export default DropdownFilters;
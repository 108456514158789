import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateBadge } from "../../components/Badges";
import Button from "../../components/Button";
import Table, { TableCell } from "../../components/Tables";
import { useAuth } from "../../context/AuthContext";
import organizationService from "../../services/organizationService";
import addressesService from "../../services/addressesService";
import styles from "../../components/styles/tableLoader.module.css";

const OrganizationPage = () => {
  const [organization, setOrganization] = useState();
  const [organizationUsers, setOrganizationUsers] = useState();
  const [salesReps, setSalesReps] = useState();
  const [addresses, setAddresses] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth()

  const userEmail = user.email

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    const organizationResponse = await organizationService.getInfo(user.organizations[0]?.organization_name)
    setOrganization(organizationResponse[0])

    const usersResponse = await organizationService.getOrganizationUsers(user.organizations[0]?.organization_name)
    setOrganizationUsers(usersResponse)

    const salesRepsResponse = await organizationService.getSalesReps(user.organizations[0]?.organization)
    setSalesReps(salesRepsResponse)

    const addressesResponse = await addressesService.organizationAddresses(user.organizations[0]?.organization)
    setAddresses(addressesResponse)

    if (organizationResponse && usersResponse && salesRepsResponse && addressesResponse) {
      setIsLoading(false)
    }
  }
  
  const navigate = useNavigate()

  return (
    <div className="flex flex-col items-stretch justify-start gap-6">
      <section className="flex flex-col items-stretch justify-start gap-4">
        <div className="flex items-start justify-start gap-4">
          <DateBadge label="Added" date={organization?.created} />
          <DateBadge label="last update" date={organization?.updated} />
        </div>
        <h1 className="font-bold text-2xl">{organization?.name}</h1>
        <div className="flex items-start justify-start gap-8">
          <p className="font-semibold text-lg">Credit: <span className="font-bold text-xl">${organization?.credit}</span></p>
          <p className="font-semibold text-lg">Balance: <span className="font-bold text-xl">${organization?.remaining_balance?.toFixed(2)}</span></p>
        </div>
      </section>
      <section className="grid grid-cols-2 gap-8 max-md:flex max-md:flex-col">
        <div className="">
          <div className="flex items-end justify-between h-9">
            <h2 className="font-semibold text-xl">Users</h2>
            {/* <Button value="add user" /> */}
          </div>
          <div className="max-h-64 overflow-y-scroll">
            <Table headers={['name', 'username']}>
              {
                isLoading
                  ?
                    <tr>
                      <td colSpan={2}>
                        <div className={`${styles.loader} flex flex-col items-center justify-center gap-4 p-8`}>
                          <p className="font-semibold text-lg">Loading...</p>
                        </div>
                      </td>
                    </tr>
                  :
                    organizationUsers?.map((obj, index) => (
                      <tr key={index} className="odd:bg-grey">
                        <TableCell>{`${obj.organization_user.first_name} ${obj.organization_user.last_name}`} <span className="font-semibold text-xs">{obj.organization_user.email === userEmail && '(you)'}</span></TableCell>
                        <TableCell>{obj.organization_user.username}</TableCell>
                      </tr>
                    ))
              }
            </Table>
          </div>
        </div>
        <div className="">
          <div className="flex items-end justify-between h-9">
            <h2 className="font-semibold text-xl">Sales Reps</h2>
          </div>
          <div className="max-h-64 overflow-y-scroll">
            <Table headers={['name', 'username']}>
              {
                isLoading
                  ?
                    <tr>
                      <td colSpan={2}>
                        <div className={`${styles.loader} flex flex-col items-center justify-center gap-4 p-8`}>
                          <p className="font-semibold text-lg">Loading...</p>
                        </div>
                      </td>
                    </tr>
                  :
                    salesReps?.map((user, index) => (
                      <tr key={index} className="odd:bg-grey">
                        <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                        <TableCell>{user.username}</TableCell>
                      </tr>
                    ))
              }
            </Table>
          </div>
        </div>
      </section>
      <div className="">
        <div className="flex items-end justify-between h-9 mb-2">
          <h2 className="font-semibold text-xl">Addresses</h2>
          <Button value="add address" onClick={() => navigate('add-address', { state: { organizationID: organization.id}})} />
        </div>
        <div className="overflow-y-scroll">
          <Table headers={['street', 'exterior', 'interior', 'city', 'country']}>
            {
              isLoading
                ?
                  <tr>
                    <td colSpan={5}>
                      <div className={`${styles.loader} flex flex-col items-center justify-center gap-4 p-8`}>
                        <p className="font-semibold text-lg">Loading...</p>
                      </div>
                    </td>
                  </tr>
                :
                  addresses?.map((address, index) => (
                    <tr key={index} className="odd:bg-grey">
                      <TableCell>{address.address_detail.street}</TableCell>
                      <TableCell>{address.address_detail.exterior}</TableCell>
                      <TableCell>{address.address_detail.interior || "----"}</TableCell>
                      <TableCell>{address.address_detail.city}</TableCell>
                      <TableCell>{address.address_detail.country_detail?.printable_name || "----------"}</TableCell>
                    </tr>
                  ))
            }
          </Table>
        </div>
      </div>
    </div>
  );
}
 
export default OrganizationPage;
import { useEffect, useState } from "react";
import ArrowLeft from "../../assets/icons/ArrowLeft.svg";
import BrandLogo from "../../assets/images/BrandLogo.png";
import itemService from "../../services/itemsService";
import ProductCard from "../../components/ProductCard";
import Button from "../../components/Button";
import RadioButton from "../../components/RadioButton";
import InputRange from "../../components/InputRange";
import MagnifyingGlass from "../../assets/icons/MagnifyingGlass.svg"
import Funnel from "../../assets/icons/Funnel.svg"
import { useShoppingCart } from "../../context/ShoppingCartContext";

const PlaceOrderPage = () => {
  const [brands, setBrands] = useState();
  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [brandSelected, setBrandSelected] = useState({});
  const [items, setItems] = useState();
  const [itemsFiltered, setItemsFiltered] = useState(items);
  const [nameFilter, setNameFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState({
    list: [],
    selected: {}
  });
  const [priceRange, setPriceRange] = useState({
    lower: Number.MAX_VALUE,
    higher: Number.MIN_VALUE,
  });
  const [minPrice, setMinPrice] = useState(priceRange.lower)
  const [maxPrice, setMaxPrice] = useState(priceRange.higher)
  const [priceFilters, setPriceFilters] = useState({
    min_price: priceRange.lower,
    max_price: priceRange.higher
  });

  const [isFiltersMenuOpen, setIsFiltersMenuOpen] = useState(false);
  
  const fetchCategories = async () => {
    const categories = await itemService.categoriesList()

    if (categories) {
      const brandCategoryID = categories.find(category => category.name.toLowerCase() === "marca" || category.name.toLowerCase() === "brand")?.id || null

      if (brandCategoryID) {
        const categoriesFiltered = categories.filter(category => category.parent === brandCategoryID)
        const categoriesSorted = categoriesFiltered.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        setBrands(categoriesSorted)
      }

      const categoriesFiltered = categories.filter(category => category.parent === 1 && category.slug !== 'marca')
      setCategoryFilter(prev => ({
        ...prev,
        list: categoriesFiltered.filter(category => category.id !== brandSelected.id),
        selected: categoriesFiltered.reduce((acc, category) => {
          acc[category.slug] = true
          return acc
        }, {})
      }))
    }
  }

  const handleSearchChange = (event) => {
    const value = event.target.value
    setNameFilter(value)
  }

  const handleCheckboxesChange = (category) => {
    setCategoryFilter(prev => ({
      ...prev,
      selected: {
        ...prev.selected,
        [category]: !prev.selected[category]
      }
    }))
  }

  useEffect(() => {
    fetchCategories()
  }, [brandSelected]);

  useEffect(() => {
    if (items) {
      setItemsFiltered(items)
      
      let lower = priceRange.lower
      let higher = priceRange.higher
      
      items.forEach(item => {
        const price = item.organization_price !== null ? item.organization_price : item.default_price

        if (price < lower) {
          lower = price
        }

        if (price > higher) {
          higher = price
        }
      })

      setPriceRange({
        lower,
        higher
      })
      setPriceFilters(prev => ({
        ...prev,
        min_price: lower,
        max_price: higher
      }))
      setMinPrice(lower)
      setMaxPrice(higher)
    }
  }, [items]);

  useEffect(() => {
    setPriceFilters(prev => ({
      ...prev,
      min_price: minPrice,
      max_price: maxPrice
    }))
  }, [minPrice, maxPrice]);

  const fetchVapes = async (brand) => {
    const vapes = await itemService.listFiltered(`?category_slugs=${brand.slug}`)

    if (vapes) {
      setIsBrandSelected(true)
      setBrandSelected(brand)
      setItems(vapes)
      setPriceRange({
        lower: Number.MAX_VALUE,
        higher: Number.MIN_VALUE,
      })
    }
  }

  const applyFilter = () => {
    let filtered = items

    if (priceFilters.max_price !== priceRange.higher || priceFilters.min_price !== priceRange.lower) {
      filtered = filtered.filter(item => {
        if (item.organization_price) {
          return item.organization_price >= minPrice && item.organization_price <= maxPrice
        } else {
          return item.default_price >= minPrice && item.default_price <= maxPrice
        }
      })
    }
    const allCategoriesSelected = Object.values(categoryFilter.selected).every(category => category === true)
    if (!allCategoriesSelected) {
      filtered = filtered.filter(item => {
        return item.category.slug?.length === 0 || item.category.slug?.some(category => categoryFilter.selected[category])
      })
    }
    if (nameFilter !== '') {
      filtered = filtered.filter(item => item.name.toLowerCase().includes(nameFilter.toLowerCase()))
    }

    setItemsFiltered(filtered)
    setIsFiltersMenuOpen(false)
  }

  const resetFilters = () => {
    setItemsFiltered(items)
    setNameFilter('')
    setPriceFilters({
      min_price: priceRange.lower,
      max_price: priceRange.higher
    })
    setCategoryFilter(prev => ({
      ...prev,
      selected: Object.keys(prev.selected).reduce((acc, category) => {
        acc[category] = true
        return acc
      }, {})
    }))
    setIsFiltersMenuOpen(false)
  }

  const { addToCart } = useShoppingCart()

  return (
    <div className="flex flex-col items-stretch justify-start gap-4 h-full">
      <div className="flex items-start justify-between gap-4">
        <div className="flex items-center justify-start gap-4">
          {
            isBrandSelected && (
              <button
                className="flex items-center justify-center w-8 h-8 p-1 rounded-full hover:bg-grey"
                onClick={() => {
                  setIsBrandSelected(false)
                  setIsFiltersMenuOpen(false)
                }}
              >
                <img src={ArrowLeft} alt="Black arrow pointing to the left indicating that it is a button to return to the previous page." className="w-full h-full object-cover" />
              </button>
            )
          }
          <h1 className="font-bold text-2xl">{isBrandSelected ? brandSelected.name.toUpperCase() : "Vape Brands"}</h1>
        </div>
        {
          isBrandSelected && (
            <div className="flex items-start justify-end gap-4">
              <form
                className="flex items-stretch justify-center w-96 h-8 rounded-lg overflow-hidden max-md:w-auto"
                onSubmit={(e) => {
                  e.preventDefault()
                  applyFilter()
                }}
              >
                <button type="submit" className="flex-shrink-0 flex items-center justify-center w-12 bg-gray-400 outline-none">
                  <img src={MagnifyingGlass} alt="Magnifying glass icon" className="w-5 h-5" />
                </button>
                <input
                  type="search"
                  name="search"
                  placeholder="Search by name"
                  className="flex-1 bg-grey outline-none px-4 text-lg"
                  value={nameFilter}
                  onChange={handleSearchChange}
                />
              </form>
              <div className="relative h-8">
                <button className="w-8 h-8 px-1.5 rounded-lg outline-none border border-black" onClick={() => setIsFiltersMenuOpen(!isFiltersMenuOpen)}>
                  <img src={Funnel} alt="Funnel icon" className="w-full h-auto" />
                </button>
                <div className={`${!isFiltersMenuOpen ? 'hidden' : 'flex'} flex-col gap-4 absolute bg-white w-96 h-auto px-5 py-4 right-0 top-[130%] rounded-lg border-2 border-black z-40`}>
                  <InputRange
                    label="price"
                    minValue={priceFilters.min_price}
                    maxValue={priceFilters.max_price}
                    setMinValue={setMinPrice} 
                    setMaxValue={setMaxPrice}
                    minRange={priceRange.lower}
                    maxRange={priceRange.higher}
                    valueGap={100}
                  />
                  <div>
                    <p className='capitalize text-sm font-semibold after:content-[":"]'>Categories</p>
                    <div className="flex flex-col items-start justify-start gap-3">
                      {
                        categoryFilter.list.map((category) => (
                          <RadioButton
                            key={category.slug}
                            id={category.slug}
                            label={category.name}
                            value={category.slug}
                            isCheckbox
                            checked={categoryFilter.selected[category.slug]}
                            onChange={() => handleCheckboxesChange(category.slug)}
                          />
                        ))
                      }
                    </div>
                  </div>
                  <div className="flex items-center justify-end gap-4">
                    <Button value="Reset" btnStyle="secondary" onClick={resetFilters} />
                    <Button value="Apply" onClick={applyFilter} />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      {
        !isBrandSelected
          ?
            <section className="grid gap-8 py-4" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(18rem, 1fr))' }}>
              {
                brands?.map(brand => (
                  <article key={brand.id} className="flex flex-col items-center justify-start gap-4 p-4 rounded-2xl bg-grey cursor-pointer hover:shadow-[0_0px_10px_2px_rgba(0,0,0,0.4)]" onClick={() => fetchVapes(brand)}>
                    <figure className="w-20 h-auto aspect-square overflow-hidden rounded-lg">
                      <img src={BrandLogo} alt="Brand logo" />
                    </figure>
                    <p className="font-semibold text-lg">{brand.name.toUpperCase()}</p>
                  </article>
                ))
              }
            </section>
          : 
            <div className="grid gap-4 content-start overflow-y-scroll pr-1" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))', height: 'calc(100% - 3rem)' }}>
              {
                itemsFiltered?.map((product) => (
                  <ProductCard
                    key={product.id}
                    product={product}
                    addToCart={addToCart}
                  />
                ))
              }
            </div>
      }
    </div>
  );
}
 
export default PlaceOrderPage;
import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import InputGroup from '../../components/InputGroup';
import Button from '../../components/Button';
import { useAuth } from '../../context/AuthContext';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, loggedIn } = useAuth()

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const success = await login(email, password)
            if(success){
                navigate("/dashboard")
            }
        } catch (error) {
            console.error("Login failed!", error);
        }
    };

    const handleUsernameChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    return loggedIn ? <Navigate to="/dashboard" /> : (
        <div className="flex items-center justify-center h-screen">
            <div className='flex flex-col items-center justify-start gap-4 max-w-96 w-full'>
                <h1 className='text-3xl font-bold'>Login</h1>
                <form className='self-stretch flex flex-col gap-4' onSubmit={handleSubmit}>
                    <InputGroup label='Email' id='username' name='username' type='text' value={email} onChange={handleUsernameChange}/>
                    <InputGroup label='password' id='password' name='password' type='password' value={password} onChange={handlePasswordChange} />
                    <Link to='/reset-password' className='self-end font-medium text-grey-800'>Forgot your password?</Link>
                    <Button value='Login' type='submit' />
                </form>
            </div>
        </div>
    );
};

export default Login;

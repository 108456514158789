import { getFormattedDate } from "../utils/dateFormatter"

const StateTimeline = ({ states }) => {
  const getStateHour = (date) => {
    const newDate = new Date(date)
    const hours = newDate.getHours()
    const minutes = newDate.getMinutes()
    return `${hours}:${minutes}`
  }

  return (
    <div className="flex items-start justify-start max-w-full overflow-x-scroll">
      {
        states.map(state => (
          <div key={state.id} className="min-w-40">
            <div className="flex items-center w-full">
              <div className="flex-1 border-t border-b border-dashed border-black" />
              <div className="flex-shrink-0 w-3.5 h-3.5 rounded-full bg-black" />
            </div>
            <div className="pr-3.5">
              <div className="flex items-start justify-between gap-4">
                <span className="font-semibold text-xs">{getFormattedDate(state.updated)}</span>
                <span className="font-semibold text-xs">{getStateHour(state.updated)}</span>
              </div>
              <p className="font-bold text-sm capitalize">{state.state}</p>
              <p className="text-xs">{state.username}</p>
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default StateTimeline;
// import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react";
import Button from "../../../components/Button"
import InputGroup from "../../../components/InputGroup"
import SelectInput from "../../../components/SelectInput";
import addressesService from "../../../services/addressesService";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const AddAddress = () => {
  const { user } = useAuth()
  const organizationID = user?.organizations[0]?.organization

  const navigate = useNavigate()

  const [countries, setCountries] = useState();

  useEffect(() => {
    fetchCountries()
  }, [])

  const fetchCountries = async () => {
    const response = await addressesService.getCountries()
    const options = response?.map(country => ({
      id: country.iso_3166_1_a2,
      name: country.printable_name
    }))
    setCountries(options)
  }

  const [newAddress, setNewAddress] = useState({
    organizations: organizationID,
    address: {
      street: '',
      exterior: '',
      interior: '',
      city: '',
      zip_code: '',
      country: 0
    }
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setNewAddress(prev => ({
      ...prev,
      address: {
        ...prev.address,
        [name]: value
      }
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const success = await addressesService.create(newAddress)
    
    if (success) {
      navigate("/organization")
    }
  }

  return (
    <div className="flex items-center justify-center" style={{ height: 'calc(100% - 5rem)' }}>
      <div className="flex flex-col items-center justify-start gap-4 max-w-96 w-full">
        <h1 className="text-3xl font-bold">Add Address</h1>
        <form className="self-stretch flex flex-col gap-4" onSubmit={handleSubmit}>
          <InputGroup label='street' name="street" value={newAddress.address.street} onChange={handleChange} />
          <InputGroup label='exterior' name="exterior" value={newAddress.address.exterior} onChange={handleChange} />
          <InputGroup label='interior' name="interior" value={newAddress.address.interior} onChange={handleChange} />
          <InputGroup label='city' name="city" value={newAddress.address.city} onChange={handleChange} />
          <InputGroup label='zip code' name="zip_code" value={newAddress.address.zip_code} onChange={handleChange} />
          <SelectInput label='country' name="country" options={countries} value={newAddress.address.country} onChange={handleChange} />
          <Button value="Create" type="submit" />
        </form>
      </div>
    </div>
  )
}
 
export default AddAddress
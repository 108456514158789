import { createContext, useContext, useState } from "react";
import authenticationService from "../services/authenticationService";


const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(
    () => localStorage.getItem('user') !== null
  )

  const [user, setUser] = useState(
    () => JSON.parse(localStorage.getItem('user'))
  )

  const login = async (email, password) => {
    const response = await authenticationService.login(email, password)

    if (response.user) {
      setLoggedIn(true)
      setUser(response.user)
      return true
    } else {
      return false
    }
  }

  const logout = async () => {
    try {
      const response = await authenticationService.logout()

      if (response) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        setLoggedIn(false)
      }
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }

  return (
    <AuthContext.Provider value={{ loggedIn, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export default AuthContext

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ChartBars from "../../assets/icons/ChartBars.svg";
import Clipboard from "../../assets/icons/Clipboard.svg";
import Wallet from "../../assets/icons/Wallet.svg";
import ShoppingCart from "../../assets/icons/ShoppingCart.svg";
import UserGroup from "../../assets/icons/UserGroup.svg";
import Bars3 from "../../assets/icons/Bars3.svg";

const Sidemenu = ({ className, withHeader = false, toggleOpen }) => {
  const [currentPath, setCurrentPath] = useState('');
  const { pathname } = useLocation()

  useEffect(() => {
    setCurrentPath(pathname)
    if (toggleOpen) {
      toggleOpen()
    }
  }, [pathname])

  const getCurrent = (href) => {
    return currentPath.startsWith(href) ? 'bg-grey' : ''
  }

  return (
    <aside className={`flex-shrink-0 bg-grey-300 w-60 h-full ${className}`} onClick={(e) => e.stopPropagation()}>
      {
        withHeader && toggleOpen &&
          <div className="flex items-center justify-start gap-8 h-20 bg-grey px-8">
            <button type="button" onClick={toggleOpen} className="w-10 h-10 p-1 rounded-lg hover:bg-grey-600">
              <img src={Bars3} alt="Burguer menu icon" />
            </button>
          </div>
      }
      <nav className="flex flex-col items-stretch justify-start gap-4 py-4 px-4">
        <Link to='/dashboard' className={`flex items-center justify-start gap-4 px-4 py-2 rounded-md capitalize hover:bg-grey ${getCurrent('/dashboard')}`}>
          <img src={ChartBars} alt="Chart Bars Icon" className="w-6 h-6" />
          <span>dashboard</span>
        </Link>
        <Link to='/orders' className={`flex items-center justify-start gap-4 px-4 py-2 rounded-md capitalize hover:bg-grey ${getCurrent('/orders')}`}>
          <img src={Clipboard} alt="Chart Bars Icon" className="w-6 h-6" />
          <span>orders</span>
        </Link>
        <Link to='/payments' className={`flex items-center justify-start gap-4 px-4 py-2 rounded-md capitalize hover:bg-grey ${getCurrent('/payments')}`}>
          <img src={Wallet} alt="Chart Bars Icon" className="w-6 h-6" />
          <span>payments</span>
        </Link>
        <Link to='/place-order' className={`flex items-center justify-start gap-4 px-4 py-2 rounded-md capitalize hover:bg-grey ${getCurrent('/place-order')}`}>
          <img src={ShoppingCart} alt="Chart Bars Icon" className="w-6 h-6" />
          <span>place order</span>
        </Link>
        <Link to='/organization' className={`flex items-center justify-start gap-4 px-4 py-2 rounded-md capitalize hover:bg-grey ${getCurrent('/organization')}`}>
          <img src={UserGroup} alt="Chart Bars Icon" className="w-6 h-6" />
          <span>organization</span>
        </Link>
      </nav>
    </aside>
  );
}
 
export default Sidemenu;
import { DateBadge } from "../../components/Badges";
import InputGroup from "../../components/InputGroup";
import { useAuth } from "../../context/AuthContext";

const ProfilePage = () => {
  const { user } = useAuth()

  return (
    <div className="max-w-[50rem] m-auto">
      <div className="flex items-end justify-between">
        <div className="flex flex-col items-start justify-between self-stretch">
          <div className="flex items-start justify-start gap-4">
            <DateBadge label="joined" date={user.organizations[0].date_joined} />
            <DateBadge label="last login" date={user.organizations[0].last_login} />
          </div>
          <h1 className="font-bold text-2xl">{`${user.first_name} ${user.last_name}`}</h1>
        </div>
        <figure className="w-56 h-auto max-md:w-32 aspect-square bg-grey rounded-lg overflow-hidden">
          <img className="w-full h-full object-cover" src={user.profile?.image || "https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_1280.jpg"} alt={`${user.first_name} ${user.last_name}`} />
        </figure>
      </div>
      <div className="grid grid-cols-2 gap-8 py-8 max-md:flex max-md:flex-col-reverse max-md:items-end">
        <section className="max-md:self-stretch flex flex-col items-stretch justify-start gap-4">
          <InputGroup id="username" label='username' value={user.username} isDisabled />
          <InputGroup id="email" type="email" label='email' value={user.email} isDisabled />
          <InputGroup id="first_name" label='first name' value={user.first_name} isDisabled />
          <InputGroup id="last_name" label='last name' value={user.last_name} isDisabled />
        </section>
        <section className="flex flex-col items-end justify-start">
          <h2 className="font-semibold text-xl">Organization</h2>
          <p className="font-bold text-2xl">{user.organizations[0]?.organization_name}</p>
        </section>
      </div>
    </div>
  );
}
 
export default ProfilePage;
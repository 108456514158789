const InputGroup = ({ id = '', name = '', type = 'text', label, placeholder = '', helpText = 'Help Text', showHelpText = false, value, onChange, isDisabled = false, isTextArea = false, size}) => {
  return (
    <div className='flex flex-col'>
      {label && <label className='capitalize text-sm font-semibold after:content-[":"]' htmlFor={id}>{label}</label> }
      {
        isTextArea
          ?
            <textarea
              className={`h-fit min-h-32 text-base py-2.5 border-black placeholder:text-grey-800 outline-none ${!isDisabled ? 'px-4 border rounded-lg resize-y' : 'px-0 border-b resize-none'}`}
              id={id}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              readOnly={isDisabled}
            />
          :
            <input
              className={`text-base border-black placeholder:text-grey-800 outline-none ${size === "small" ? 'text-xs py-2' : 'text-base py-2.5'} ${!isDisabled ? 'px-4 border rounded-lg' : 'px-0 border-b'}`}
              id={id}
              name={name}
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              readOnly={isDisabled}
            />
      }
      {
        showHelpText && <span className='text-xs'>{helpText}</span>
      }
    </div>
  )
}

export default InputGroup
import { useEffect, useRef } from 'react';
import styles from './styles/inputRange.module.css'

const InputRange = ({ name = '', id = '', label = 'label', minRange = 0, maxRange = 100, minValue = 0, maxValue = 100, setMinValue, setMaxValue, valueGap = 10 }) => {
  const progressBar = useRef(null)

  const handleMinValue = (event) => {
    const value = parseInt(event.target.value, 10)
    if (value >= maxValue - valueGap) {
      setMinValue(parseInt(maxValue - valueGap))
    } else {
      setMinValue(parseInt((value === '' || !Number(value)) ? 0 : value))
    }
  }

  const handleMaxValue = (event) => {
    const value = parseInt(event.target.value, 10)
    if (value <= minValue + valueGap) {
      setMaxValue(parseInt(minValue + valueGap))
    } else if (value <= maxRange) {
      setMaxValue(parseInt((value === '' || !Number(value)) ? (minValue + valueGap) : value))
    } else {
      setMaxValue(maxRange)
    }
  }

  useEffect(() => {
    progressBar.current.style.left = `${((minValue - minRange) / (maxRange - minRange)) * 100}%`
    progressBar.current.style.right = `${100 - ((maxValue - minRange) / (maxRange - minRange)) * 100}%`
  }, [maxValue, minValue, maxRange, minRange])

  return (
    <div>
      <label className='capitalize text-sm font-semibold after:content-[":"]' htmlFor={`${id}_min_value`}>{label}</label>
      <div className='flex flex-col gap-4'>
        <div>
          <div className="relative h-2 bg-grey rounded">
            <div ref={progressBar} className={`absolute h-2 bg-black rounded`}></div>
          </div>
          <div className="relative">
            <input
              type="range"
              name={`${name}_min_range`}
              id={`${id}_min_range`}
              min={minRange}
              max={maxRange}
              className={`${styles.range} absolute -top-2 h-2 w-full appearance-none bg-transparent pointer-events-none`}
              value={minValue}
              onChange={handleMinValue}
            />
            <input
              type="range"
              name={`${name}_max_range`}
              id={`${id}_max_range`}
              min={minRange}
              max={maxRange}
              className={`${styles.range} absolute -top-2 h-2 w-full appearance-none bg-transparent pointer-events-none`}
              value={maxValue}
              onChange={handleMaxValue}
            />
          </div>
        </div>
        <div className="flex items-end justify-between gap-4">
          <div className="flex items-end justify-start gap-2.5">
            <label className='capitalize text-sm font-semibold after:content-[":"]' htmlFor={`${id}_min_value`}>min</label>
            <input
              id={`${id}_min_value`}
              name={`${name}_min_value`}
              className={`${styles.inputNumber} leading-none outline-none w-24 px-4 py-2.5 text-base font-medium border border-black rounded-lg`}
              min={minRange}
              value={minValue}
              onChange={handleMinValue}
            />
          </div>
          <div className="flex items-end justify-start gap-2.5">
            <label className='capitalize text-sm font-semibold after:content-[":"]' htmlFor={`${id}_max_value`}>max</label>
            <input
              id={`${id}_max_value`}
              name={`${name}_max_value`}
              className={`${styles.inputNumber} leading-none outline-none w-24 px-4 py-2.5 text-base font-medium border border-black rounded-lg`}
              max={maxRange}
              value={maxValue}
              onChange={handleMaxValue}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputRange;
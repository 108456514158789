import PlusCircle from "../assets/icons/PlusCircle.svg"
import MinusCircle from "../assets/icons/MinusCircle.svg"
import { useState } from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import defaultImage from "../assets/images/logo371.png"

const ProductCard = ({ product, addToCart }) => {
  const [quantity, setQuantity] = useState(1)

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10)

    if (value > 999) {
      setQuantity(999)
    } else {
      setQuantity((value === '' || !Number(value)) ? 0 : parseInt(value))
    }
  }

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const increaseQuantity = () => {
    if (quantity < 999) {
      setQuantity(quantity + 1)
    }
  }

  const navigate = useNavigate()

  const truncateText = (text) => {
    if (text?.length > 100) {
      return text.substring(0, 100).trim() + '...'
    } else {
      return text
    }
  }

  return (
    <article className="flex flex-col items-stretch justify-between gap-1.5 p-4 rounded-2xl bg-white border border-black hover:bg-grey" onClick={() => navigate('item', { state: { item: product}})}>
      <figure className="self-center w-[80%] bg-grey aspect-square rounded-lg overflow-hidden">
        <img
          src={product.images[0] || defaultImage}
          alt={product.images.length !== 0 ? product.name : `You are seeing the Logistika Urbana logo in white on a blue background because there is no image available for ${product.name}.`}
          className="w-full h-full object-cover"
        />
      </figure>
      <div className="flex-1 flex flex-col items-stretch justify-between gap-0.5">
        <h3 className="capitalize text-sm font-semibold text-balance">{product.name}</h3>
        <p className="text-xs text-balance">{truncateText(product.description)}</p>
        <div className="flex items-center justify-between">
          <p className="text-lg font-bold">${product.organization_price || product.default_price}</p>
          <div className="flex items-center justify-center gap-1">
            <button
              className="flex-shrink-0"
              onClick={(e) => {
                e.stopPropagation()
                decreaseQuantity()
              }}
            >
              <img src={MinusCircle} alt="Minus circle icon" className="w-6 h-6" />
            </button>
            <input
              id={product.id}
              className="w-10 bg-transparent outline-none font-semibold text-xl text-center"
              value={quantity}
              onChange={handleChange}
              onClick={(e) => e.stopPropagation()}
            />
            <button
              className="flex-shrink-0"
              onClick={(e) => {
                e.stopPropagation()
                increaseQuantity()
              }}
            >
              <img src={PlusCircle} alt="Plus circle icon" className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      <Button
        value="add"
        onClick={(e) => {
          e.stopPropagation()
          addToCart({
            ...product,
            quantity: quantity
          })
        }} />
    </article>
  );
}
 
export default ProductCard;
import { useEffect, useState } from "react";
import DateRange from "../../components/DateRange";
import { useAuth } from "../../context/AuthContext";
import organizationService from "../../services/organizationService";
import DashboardInfo from "../../components/DashboardInfo";
import LineChart from "../../components/LineChart";

const DashboardPage = () => {
  const { user } = useAuth();

  const [dateRange, setDateRange] = useState({
    from: "",
    to: ""
  });
  const [organization, setOrganization] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const fetchOrganizationData = async (startDate, endDate) => {
    setIsLoading(true)
    const response = await organizationService.dashboardStats(startDate, endDate)
    
    if (response) {
      setOrganization(prevData => ({
        ...prevData,
        ...response,
        ordersChart: response.orders_by_week.reduce((acc, current) => {
          acc.labels.push(current.date)
          acc.values.push(current.order_count)
          return acc
        }, { labels: [], values: [] }),
        spentChart: response.spent_by_week.reduce((acc, current) => {
          acc.labels.push(current.date)
          acc.values.push(current.spent_amount)
          return acc
        }, { labels: [], values: [] })
      }))
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const today = new Date()
    const lastMonth = new Date(today)
    lastMonth.setMonth(lastMonth.getMonth() - 2)

    if (lastMonth.getDate() !== today.getDate()) {
      lastMonth.setDate(0)
    }

    setDateRange(prevRange => ({
      ...prevRange,
      from: formatDate(lastMonth),
      to: formatDate(today)
    }))

    fetchOrganizationData(formatDate(lastMonth), formatDate(today))
  }, [])

  const searchData = () => {
    fetchOrganizationData(dateRange.from, dateRange.to)
  }

  return (
    <div className="flex flex-col items-stretch justify-start gap-8">
      <div className="flex items-start justify-between">
        <div>
          <h2 className="font-semibold text-2xl">Hi, {user.first_name}</h2>
          <p className="text-sm">Here is the data from your organization: <strong>{user.organizations[0].organization_name}</strong></p>
        </div>
        <DateRange startDate={dateRange.from} endDate={dateRange.to} setDate={setDateRange} applyChanges={searchData} />
      </div>
      <div className="flex items-start justify-between gap-4 flex-wrap">
        <DashboardInfo label="Credit" value={isLoading ? "Loading..." : `$${organization.remaining_credit}`} />
        <DashboardInfo label="Remaining Balance" value={isLoading ? "Loading..." : `$${organization.balance}`} />
        <DashboardInfo label="Orders Placed" value={isLoading ? "Loading..." : organization.total_orders} />
        <DashboardInfo label="Total Spent" value={isLoading ? "Loading..." : `$${organization.total_spent}`} />
      </div>
      <div className="flex items-start justify-center gap-8 max-xl:flex-col max-xl:items-center max-xl:justify-start">
        <article className="flex flex-col items-start justify-start gap-4 w-full h-auto p-4 bg-grey rounded-2xl">
          <h3 className="font-semibold text-base">Orders Placed</h3>
          <LineChart
            labels={organization?.ordersChart?.labels}
            data={organization?.ordersChart?.values}
          />
        </article>
        <article className="flex flex-col items-start justify-start gap-4 w-full h-auto p-4 bg-grey rounded-2xl">
          <h3 className="font-semibold text-base">Total Spent</h3>
          <LineChart
            labels={organization?.spentChart?.labels}
            data={organization?.spentChart?.values}
          />
        </article>
      </div>
    </div>
  );
}

export default DashboardPage;
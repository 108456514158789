import { useEffect, useState } from "react";
import PlusCircle from "../assets/icons/PlusCircle.svg"
import MinusCircle from "../assets/icons/MinusCircle.svg"
import TrashIcon from "../assets/icons/Trash.svg"
import { useShoppingCart } from "../context/ShoppingCartContext";
import tempImage from "../assets/images/vape.svg"

const ProductShoppingCard = ({ product, currentIndex, isCheckoutCard = false }) => {
  const [quantity, setQuantity] = useState(product.quantity)

  const { deleteFromCart, setShoppingCart } = useShoppingCart()

  useEffect(() => {
    setShoppingCart(prev => {
      return prev.map((item, index) => index === currentIndex ? { ...item, quantity: quantity } : item)
    })
  }, [quantity])

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10)

    if (value > 999) {
      setQuantity(999)
    } else {
      setQuantity((value === '' || !Number(value)) ? 0 : parseInt(value))
    }
  }

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const increaseQuantity = () => {
    if (quantity < 999) {
      setQuantity(quantity + 1)
    }
  }

  return (
    <article className={`flex items-stretch justify-start gap-1.5 p-2 rounded-2xl ${isCheckoutCard ? 'bg-grey min-w-[28rem] max-md:min-w-full' : 'bg-white'}`}>
      <figure className={`flex-shrink-0 self-center ${isCheckoutCard ? 'w-28 bg-white' : 'w-20 border-2 border-black'} aspect-square rounded-md overflow-hidden`}>
        <img src={product.item_images?.image || tempImage} alt={product.name} className="w-full h-full object-cover" />
      </figure>
      <div className="flex flex-col items-start justify-between gap-0.5 w-full">
        <div className="flex items-start justify-between gap-2 w-full">
          <h3 className={`flex-1 capitalize ${isCheckoutCard ? 'text-lg' : 'text-sm'} font-semibold text-balance`}>{product.name}</h3>
          <button className={`flex-shrink-0 ${isCheckoutCard ? 'w-5' :'w-4'} h-auto`} onClick={() => deleteFromCart(currentIndex)}>
            <img src={TrashIcon} alt="Trash Icon" />
          </button>
        </div>
        <div className={`${isCheckoutCard ? 'flex items-end justify-between w-full' : ''}`}>
          <div className="flex flex-col items-start justify-start gap-2">
            {
              isCheckoutCard && <p className="capitalize font-semibold">unit price: <span className="text-lg font-bold">${product.organization_price || product.default_price}</span></p>
            }
            <div className="flex items-center justify-center gap-2">
              <button className="flex-shrink-0" onClick={decreaseQuantity}>
                <img src={MinusCircle} alt="Minus circle icon" className="w-6 h-6" />
              </button>
              <input
                id={`${product.id}_${isCheckoutCard ? 'checkout' : 'cart'}`}
                className="w-10 bg-transparent outline-none font-semibold text-xl text-center"
                value={product.quantity}
                onChange={handleChange}
              />
              <button className="flex-shrink-0" onClick={increaseQuantity}>
                <img src={PlusCircle} alt="Plus circle icon" className="w-6 h-6" />
              </button>
            </div>
          </div>
          {
            isCheckoutCard && <p className="capitalize font-semibold">total: <span className="text-lg font-bold">${(product.organization_price || product.default_price) * product.quantity}</span></p>
          }
        </div>
      </div>
    </article>
  )
}
 
export default ProductShoppingCard;